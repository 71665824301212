body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
a{
	color:#214fb8;
}

.menu-icon{
 transform: scale(1.3);
}
.active-menu-item [class*="MuiTouchRipple"]{
	display:none;
}

.menu-item{
    padding-top:12px !important;
    padding-bottom:12px !important;
}
.active-menu-item{
    color: black !important;
}
.active-menu-item:after{
	content:"";
	position:absolute;
	top: 0;
    left: 0;
    bottom:0;
    width:4px;
    background: #08C;

}

.logout>span:first-child{
    height: 24px;
}

.table-img{
    max-width: 150px;
    max-height: 150px;
    display: block;
    object-fit: contain;
    cursor:pointer;
}
.datagrid-body .table-img{
  max-width: 70px;
  max-height: 38px;
}


.column-undefined{
    width:1px;
    padding-left: 0 !important;


}
.column-undefined:not(:last-child){
    padding-right: 0 !important;   
}
.side-menu-open .sub-menu>a{
    padding-left:35px;
}

.side-menu-letter-icon{
    display:flex !important;
    align-items:center;
    justify-content:center;
}


.input-cont{
    display: flex;
    align-items: flex-start;
    width: max-content;
    max-width: 100%;
    flex-wrap: wrap;
}
.input-cont-markdown{
    width:100% !important;
}


.rc-md-editor:not(.full){
    height: max-content;
    min-height: 350px;
    margin-top: 23px;

}
.small-editor .rc-md-editor:not(.full){

    min-height: 150px;

}

.rc-md-editor:not(.full) .rc-md-navigation{
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    z-index:2;
}

.rc-md-editor.full{
    z-index:10000;
}
.editor-container .section+.section{
    border-left:1px solid #e0e0e0;
}

html{
    overflow-y:scroll;
}


.list-card{
    overflow:visible !important;
}
.min-td{
    width: 1px;
}

.filter-field input{
    width:100%;
}
/*8
table,tbody,thead{display:block !important;}
tr{
    display:grid !important;
    grid-auto-flow:column;
    grid-template-columns:var(--cols);
}
td,th{
    display:flex !important;
        align-items: center;

        }*/





        .form-tabs-vertical{
            --tabTitleH:48px;
            --sideW:160px;
        }


        .form-tab-title{
            background:orange;
            position:sticky;
            bottom:calc(64px + var(--offsetBottom) * var(--tabTitleH));


            top: calc(50px + var(--offset) * var(--tabTitleH));
            z-index:20;
            height:0;
        }
        .form-tab-title-text{
            position:absolute;
            cursor:pointer;
            width: var(--sideW);

        }



        @media (max-width:959px){
            .form-tabs-vertical .form-tab-title{
                display:none;
            }
        }
        @media (min-width:960px){
            .form-tab-content{
              position: relative;
              margin-left: var(--sideW);
              border-left:1px solid rgba(0, 0, 0, 0.12);
              padding:16px 16px 16px 32px;
          }
          .form-tab-content~.form-tab-content{
           border-top:1px solid rgba(0, 0, 0, 0.12);
       }
       .form-tab-scroller{
        position:absolute;
        bottom:0;
        left:0;
        height:calc(100% + 50px);
    }
    .pad0{
        padding:0 !important;
    }

}




.embed-responsive{
    background:black;
    position:relative;

    padding-bottom:56.25%;
}
.embed-responsive>*{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}





.input-cont-slider{
    width:100% !important;
}
.MuiSlider-root{
    padding: 45px 0 30px !important;
    max-width:350px;
}
.input-cont-array{
    width: 100% !important;
}
.grid-array{
    width: 100% !important;
}
.grid-array .MuiFormControl-root{
    min-width:0 !important;
}

.single-element-array .MuiTypography-body1:not(.MuiFormControlLabel-label){
    display:none;
}
.input-cont-longtext,.long-text-input{
    width: 100% ;
}



.entity-form .MuiTextField-root{
    max-width:600px;
    min-width:350px;
    
}




.markdown img, .legend-img{
    max-width: 100%;
    height: auto;
    margin: 1em 0;
    border: 1px dashed #0088cc;
    padding: 10px;
    box-sizing: border-box;
}
.markdown code{
  background: rgba(0, 0, 0, 0.05);
  color: #393939;
  padding: 3px;
  display: inline-block;
}
.markdown li ul, .markdown li ol{
    margin:0;
}

.header-anchor{
 font-size: 16px;
 color: #0052d9;
 display: inline-block;
 vertical-align: middle;
 cursor: pointer;
}


.entity-links .MuiChip-root{
    margin: 4px;
}


/*
.list-with-map >*:last-child>*{
      position: relative;
    z-index: 1;
    background: white;
}
.list-with-map >*:last-child{
    flex-direction: column;
}

.list-map-container{
    order:-1;
    width: 100%;
    min-height: 150px;
    height: 33vh;
    max-height: 300px;
    background:#EBE9E4;
}
.list-map-marker{
    --w:30px;
    width:var(--w);
    height:var(--w);
    margin:calc(-1 * var(--w)) 0 0 calc(-0.5 * var(--w));
    cursor:pointer;
}
.list-with-map th.MuiTableCell-root{
    position:static;
}*/



.diff-table, .diff-table ul{
    list-style:none;
    margin:0;
    padding:0;
}
.diff-label{
    padding-right:10px;
    color:#6b6a68;
}
.diff-label-array{
    padding-right:10px;
}
.diff-level{
    display:flex;

}



.access-limited .product-form .MuiToolbar-root .ra-delete-button{
    display:none;
}
.access-limited .role-editor{
    display:none;
}
.access-full .role-supplier{
    display:none;
}

.group-wrapper{
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 15px 10px 15px;
    margin-top: 15px;
}
.group-summary{
    outline: none;
    cursor: pointer;

}
.group-cont{
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
}