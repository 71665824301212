.thumbNailView img {
  width: 50px;
  height: 50px;
  object-fit: contain; }

.thumbNailMedium {
  width: 100px;
  height: 100px;
  object-fit: contain; }

.dropzone-empty * {
  pointer-events: none; }

.crop-container {
  width: fit-content; }
  .crop-container img {
    max-width: 250px;
    max-height: 250px;
    min-width: 100px;
    min-height: 100px;
    object-fit: contain; }

.ReactCrop, .transp-bg {
  background-color: white !important;
  background-image: linear-gradient(45deg, #DDD 25%, transparent 25%), linear-gradient(135deg, #DDD 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #DDD 75%), linear-gradient(135deg, transparent 75%, #DDD 75%);
  background-size: 25px 25px;
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px; }

.MuiExpansionPanelSummary-expandIcon {
  position: absolute !important;
  right: 100% !important; }

.ra-rich-text-input {
  max-width: 1040px; }

.________________ql-editor {
  padding: 20px !important;
  min-height: 200px;
  background: #F7F7F7;
  font-family: 'Open Sans' !important;
  font-size: 16px !important;
  line-height: 24px !important; }
  .________________ql-editor a {
    color: #08c !important;
    text-decoration: none !important; }
  .________________ql-editor p {
    margin: 0 !important; }
  .________________ql-editor h1, .________________ql-editor h2, .________________ql-editor h3, .________________ql-editor h4, .________________ql-editor h5, .________________ql-editor h6 {
    font-family: 'Poppins' !important;
    font-weight: 500 !important; }
  .________________ql-editor h2 {
    font-size: 31px !important;
    line-height: 37px !important;
    margin: 60px 0 12px !important; }
  .________________ql-editor h3 {
    margin: 60px 0 10px !important;
    font-size: 25px !important;
    line-height: 30px !important;
    text-align: center !important; }

.ql-container {
  max-width: 700px; }

.inline-dropzone > label + div > span > div {
  width: 100%;
  height: 100%;
  box-sizing: border-box; }

.inline-dropzone > div {
  position: relative;
  padding: 0;
  width: 100%;
  height: 150px; }

.inline-dropzone .previews {
  display: none; }

.grid-array > ul {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 250px); }

.grid-array .array-row {
  display: flex;
  flex-direction: column;
  border: none;
  background: #f8f8f8;
  padding: 10px; }
  .grid-array .array-row > p {
    display: none; }

[class*="ra-input-slides"] img {
  width: 100%; }

[class*="ra-input-slides"] > * {
  margin: 0 !important;
  width: 100%; }

[class*="MuiSelect-select"] {
  min-width: 15em !important; }

.sort-ghost-cont {
  position: relative; }
  .sort-ghost-cont .array-row {
    z-index: 10; }

.disabled-cont {
  position: relative;
  text-decoration: line-through; }
  .disabled-cont label {
    text-decoration: line-through; }
  .disabled-cont::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute; }

.long-text textarea {
  height: 100%; }

.MuiDialog-container .simple-form > * {
  padding: 0 0 20px !important; }

.boolean-input {
  width: max-content; }

.option-image {
  filter: invert(1);
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px; }

.image-input-with-alt {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px 15px 15px 15px;
  margin-top: 15px; }

.image-input-with-alt .MuiFormHelperText-root {
  display: none; }
































































  .file-label{
        align-items: flex-start;
    user-select: none;
    display: flex;

    padding: 10px;
    min-height: 60px;
    position: relative;

    background: #f0efef;

  }
  .file-input{
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .preview-container{
    position: relative;
     max-width: 250px;
    max-height: 250px;
    min-width: 100px;
    min-height: 100px;
    display: block;
    z-index: 2;
  }
  .preview-container img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;

  }
  .preview-img{
    filter: saturate(0.5) contrast(0.3) brightness(1.8);
  }
  .preview-container .progress{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .file-empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    align-self: center;
  }
  .cloud-icon{
    font-size: 40px;
  }
  .preview-container :global(.uploaded-img){
    cursor: pointer;
  }

.image-bucket-uploader .file-label{
  margin: 8px 0 4px;
}
.image-bucket-uploader .MuiInputLabel-formControl{
  position: relative;
  margin-top: 5px;
}









  .progress{
    color: black;
    display: block;
    position: relative;
  }
  .dim{
    width: var(--dim);
    height: var(--dim);
  }

  .progress-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
    font-size: calc(var(--dim) * 0.3);
  }
  .progress-value small{
    font-size: .65em;
  }

  .progress-svg {
    display: block;
  }
  .progress-bg {
    stroke: currentColor;
    opacity: .3;
  }
  .progress-fill {
    stroke: currentColor;
    transition: stroke-dashoffset .3s;
  }